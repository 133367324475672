<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n src="@i18n/drone/drone_lot.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
page.title: "{name} :: จัดการอะไหล่"
page.header : "จัดการข้อมูลอะไหล่ Lot {modelName} - {name}"
page.description : "จัดการข้อมูลอะไหล่สำหรับ Drone รุ่น {modelName} Lot {name}"

page.section.initial : "อะไหล่ตั้งต้น"
page.section.optional : "อะไหล่เสริม"

lot_part.field.mode : "ปรับค่าจาก Model"

button.add_part : "เพิ่มอะไหล่"

delete.title : "ยกเลิกใช้งาน"
delete.confirm.message : "Lot นี้ไม่ทำการใช้ชิ้นส่วนนี้แล้ว ?"
initial.title : "นำกลับมา"
initial.confirm.message : "ใช้ข้อมูลอะไหล่นี้ จาก Drone Model ?"

part.field.has_serial_no : "Serial No"
initial_parts.remark : "* ไม่สามารถเปลี่ยนแปลงข้อมูลอะไหล่ตั้งต้นได้แล้ว เนื่องจากมีการลงข้อมูล Drone ไปแล้ว"
</i18n>

<template>
	<div class="page">
		<my-page-header
			:title="$t('page.header',{ name: lot.lot_name,modelName: model.name })"
			:description="$t('page.description',{  name: lot.lot_name,modelName: model.name })"/>
		<div>
			<a-button
				class="btn-page-action"
				type="primary"
				icon="plus"
				@click="clickAddPart">
				{{$t('button.add_part')}}
			</a-button>
			<a-button icon="left" class="right" @click="goBack">
				{{$t('common.back')}}
			</a-button>
		</div>
		<a-card v-for="section of displayList" :key="section.key" :bordered="false" style="margin-bottom:32px"
			:loading="loading">
			<div class="page-section-title">
				{{section.title}}
				<div v-if="$notEmpty(section.remark)" class="page-section-description">
					{{section.remark}}
				</div>
			</div>
			<a-table
				bordered class="mytable"
				:row-key="record => record.index"
				:data-source="section.value"
				:pagination="false"
				:row-class-name="formatRow"
				size="small">
				<a-table-column
					align="center"
					:width="30">
					<template slot-scope="text,record,index">
						{{index + 1}}.
					</template>
				</a-table-column>
				<a-table-column
					:sorter="tableSortSKU"
					:title="$t('part.field.sku')" data-index='part.sku'
					:width="150"/>
				<a-table-column
					:title="$t('common.table.column.detail')" data-index="part.name"
					:sorter="tableSortName">
					<template slot-scope="text,record">
						<MyPopover overlay-class-name="mycard-popover"
							:destroy-tooltip-on-hide="true">
							<template slot="content">
								<PartLiteCard :part="record.part" size="small"  link-target="_blank"/>
							</template>
							{{record.part.name}}
						</MyPopover>
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('part.field.part_type')" data-index='part.partType'
					:width="120"
					:sorter="tableSortPartType">
					<template slot-scope="text">
						{{$tenum('part_type',text)}}
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('lot_part.field.mode')" data-index='mode'
					:width="150"
					:sorter="tableSortLotMode"
					default-sort-order="ascend">
					<template slot-scope="text">
						{{$tenum('lot_part_mode',text)}}
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('part.field.has_serial_no')" data-index='part.hasSerialNo'
					align="center"
					:width="75">
					<template slot-scope="hasSerialNo">
						<a-icon v-if="hasSerialNo" type="check" class="text-success"/>
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('lot_part.field.total')"
					data-index="total"
					:sorter="tableSortTotal"
					:width="120"
					align="right">
					<template slot-scope="total">
						{{$tc_my('part.number.display',total)}}
					</template>
				</a-table-column>

				<a-table-column
					:title="$t('lot_part.field.remark')"
					data-index="remark"
					:width="50"
					align="center">
					<template slot-scope="remark">
						<MyTooltip v-if="$notEmpty(remark)"
							:destroy-tooltip-on-hide="true">
							<div slot="title" v-trim class="multiline">
								{{remark}}
							</div>
							<a-icon type="message" class="cursor-pointer" />
						</MyTooltip>
					</template>
				</a-table-column>

				<a-table-column
					v-if="section.showAction"
					class="mytable-action-col"
					:width="150"
					align="center"
					:title="$t('common.table.column.action')">
					<template slot-scope="record">
						<MyPopconfirm v-if="record.mode === 'excluded'" :title="$t('initial.confirm.message')"
							placement="left"
							@confirm="() => handleBackToModelPart(record)">
							<a>{{$t('initial.title')}}</a>
						</MyPopconfirm>
						<template v-else>
							<a @click="() => clickEditPart(record)">
								{{$t('common.table.action.update')}}
							</a>
							<a-divider type="vertical"/>
							<MyPopconfirm :title="$t('delete.confirm.message')"
								placement="left"
								@confirm="() => handleLotPartRemove(record)">
								<a>{{$t('delete.title')}}</a>
							</MyPopconfirm>
						</template>
					</template>
				</a-table-column>


			</a-table>
		</a-card>

		<DroneLotPartModal
			:visible="lotPartModalShow"
			:lot="lot"
			:lot-part="selectedLotPart"
			:model-part="selectedModelPart"
			:loading="lotPartModalLoading"
			:current-part-id-list="currentPartIdList"
			:form-error="lotPartServerErrors"
			:can-manage-initial="canManageInitialPart"
			@submit="handleLotPartModalSubmit"
			@close="handleLotPartModalClose"/>
	</div>
</template>

<script>
import axios from "axios"
import Vue from "vue"
import get from "lodash/get"
import PageMixin from '@mixins/PageMixin.vue'
import SortModelPartMixin from '@mixins/drone/SortModelPartMixin.vue'
import {Table,Divider} from "ant-design-vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import MyPopover from "@components/common/MyPopover.vue"
import MyPopconfirm from "@components/common/MyPopconfirm.vue"
import DroneLotPartModal from "@components/drone/DroneLotPartModal.vue"
import PartLiteCard from "@components/part/PartLiteCard.vue"
import { mapGetters } from 'vuex'
import ApiError from '@utils/errors/ApiError'


export default {
	components : {
		DroneLotPartModal , PartLiteCard , MyTooltip , MyPopover , MyPopconfirm ,
		"a-divider" : Divider,
		"a-table" : Table, "a-table-column" : Table.Column
	} ,
	mixins : [PageMixin,SortModelPartMixin] ,
	props : {
		model : {
			type: null,
			required: true,
			default : () => []
		} ,
		lot : {
			type: null,
			required: true,
			default : () => []
		} ,
	},
	data(){
		return {
			loading : false,
			modelParts : [] ,
			lotParts : [],
			lotPartModalShow : false,
			lotPartModalLoading : false,
			lotPartServerErrors : {} ,
			selectedLotPart : {},
			selectedModelPart : {} ,
		}
	} ,
	page() {
		return {
			title : this.$t('page.title',{name:this.lot.lot_name,modelName: this.model.name})
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		displayList(){
			const remark = this.canManageInitialPart ? undefined :  this.$t('initial_parts.remark')
			return  [
				{
					key : 'initial' ,
					title : this.$t('page.section.initial') ,
					showAction : this.canManageInitialPart ,
					value : this.initialLotParts ,
					remark
				} ,
				{
					key : 'optional' ,
					title : this.$t('page.section.optional') ,
					showAction : true,
					value : this.optionalLotParts.map((optionalLotPart, index) => {
						return {
							...optionalLotPart,
							index
						}
					})
				} ,
			]
		} ,

		currentPartIdList() {
			return this.lotParts.map((part)=> part.partId)
		} ,
		initialLotParts() {
			return this.lotParts.filter((part) => part.type == 'initial')
		} ,
		optionalLotParts() {
			return this.lotParts.filter((part) => part.type == 'optional')
		} ,
		canManageInitialPart() {
			return this.lot.id && this.lot.numDrones <= 0
		}
	} ,
	watch: {
		lot : {
			handler() {
				this.fetchData()
			} ,
			deep: true
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		formatRow(record,index) {
			switch (record.mode) {
				case 'change' : return 'change'
				case 'custom' : return 'new'
				case 'excluded' : return 'inactive'
			}
			return null
		},
		clickAddPart() {
			this.lotPartModalShow = true;
			this.selectedLotPart = {};
			this.selectedModelPart = {};
		} ,
		clickEditPart(lotPart) {
			this.selectedLotPart = lotPart;
			this.selectedModelPart = this.modelParts.find((modelPart) => modelPart.partId == lotPart.partId)
			this.lotPartModalShow = true
		} ,
		updateSubmitData(partId,responseData) {
			const index = this.lotParts.findIndex((part) => part.partId === partId);
			const deleteCustom = get(responseData,'delete_custom',false)
			const newLotPart = get(responseData,'lot_part')
			if (deleteCustom) {
				if (index >= 0) {
						this.lotParts.splice(index,1)
				}
			} else if (newLotPart) {
				newLotPart.part = this.getPartById(newLotPart.partId)
				if (index >= 0) {
					this.lotParts.splice(index,1,newLotPart)
				} else {
					this.lotParts.push(newLotPart)
				}
			}
		} ,
		handleBackToModelPart(lotPart) {
			axios.post("/api/drone-lots/"+this.lot.id+"/parts/initial",{
				droneLotId : lotPart.drone_lot_id,
				partId : lotPart.partId ,
				mode : 'initial',
			}).then((response) => {
				const data = response.data.data;
				this.updateSubmitData(data.partId,data);
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
				throw error
			})
		} ,
		handleLotPartRemove(lotPart) {
			axios.post("/api/drone-lots/"+this.lot.id+"/parts/delete",{
				drone_lot_id : lotPart.drone_lot_id,
				part_id : lotPart.partId ,
				mode : 'excluded',
			}).then((response) => {
				const data = response.data.data;
				this.updateSubmitData(data.part_id,data);
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
				throw error
			})
		} ,
		handleLotPartModalSubmit(formData) {
			this.lotPartModalLoading = true

			formData.partId = 1
			axios.post("/api/drone-lots/"+this.lot.id+"/parts/update",formData).then((response) => {
				const data = response.data.data;
				this.updateSubmitData(data.part_id,data);
				this.handleLotPartModalClose();
			}).catch((error)=> {
				this.lotPartServerErrors = error
				throw error
			}).finally(() => {
				this.lotPartModalLoading = false
			})
		} ,
		handleLotPartModalClose() {
			this.lotPartModalShow = false;
			this.lotPartServerErrors = {};
			this.selectedLotPart = {};
			this.selectedModelPart = {};
		} ,
		fetchData() {
			if (!this.lot || !this.lot.id)
				return
			this.showPageLoading(true)
			this.loading = true
			axios.get("/api/drone-lots/"+this.lot.id+"/parts").then((response) => {
				this.lotParts = response.data.data.lotParts;
				for(const lotPart of this.lotParts) {
					Vue.set(lotPart,"part",this.getPartById(lotPart.partId))
				}
				this.modelParts = response.data.data.model_parts;
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
				this.loading = false
			})
		} ,
		goBack() {
			this.$open({name:"drone_lot/parts",params:{id:this.lot.id}})
		}
	}
}
</script>
